'use client';
import './not-found.css';

const Custom404 = () => {
  return (
    <div className='base'>
      <header className='header'>
        <h1 className='error'>
          <span className='neon'>4</span>
          <span className='neon'>0</span>
          <span className='neon'>4</span>
        </h1>
      </header>
      <p className='message'>La page n&apos;a pas été trouvée</p>
      <p className='message'>Retournez à l&apos;accueil</p>
      <button className='homeButton' onClick={() => (window.location.href = '/')} aria-label="Retour à l'accueil">
        Accueil
      </button>
    </div>
  );
};

export default Custom404;
